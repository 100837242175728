import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/main.scss';
import HeroLayout from '../../components/Layouts/HeroLayout';
import Resume from '../../components/Resume/Resume';
export default () => {
  return (
    <HeroLayout metaTitle="Portfolio" metaDescription='Developer & Designer based in Bengaluru, KA'>
      <Resume />
    </HeroLayout>
  );
};
